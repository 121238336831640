import {
  DetailedHTMLProps,
  Fragment,
  ReactNode,
  TableHTMLAttributes,
  useEffect,
  useState,
} from 'react';

import {
  Row,
  SortDirection,
  Table as TableType,
  flexRender,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { CaretDown, CaretUp } from 'phosphor-react';

import { cn } from 'src/utils/classNamesHelper';
import { useKeyPress } from 'src/utils/useKeyPress';
import { useKeyboardNavigation } from 'src/utils/useKeyboardNavigation';

import { Spinner } from './Spinner';
import { TablePagination } from './TablePagination';

export const Table = <T,>({
  children,
  className,
  table,
  pagination = false,
  rowWrapper,
  loading = false,
  ...props
}: DetailedHTMLProps<
  TableHTMLAttributes<HTMLTableElement>,
  HTMLTableElement
> & {
  table?: TableType<T>;
  pagination?: boolean;
  loading?: boolean;
  rowWrapper?: (props: { children: ReactNode; row: Row<T> }) => ReactNode;
}) => {
  return (
    <>
      <table className={cn('w-full table-fixed', className)} {...props}>
        {table && (
          <>
            <TableHead table={table} />
            {loading ? (
              <tbody>
                <tr>
                  <td
                    colSpan={table.getVisibleFlatColumns().length}
                    className="h-128 text-neutral-dark-1"
                  >
                    <div className="flex size-full animate-fade-in-up items-center justify-center">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <TableBody table={table} rowWrapper={rowWrapper}>
                {children}
              </TableBody>
            )}
          </>
        )}
        {!table && <>{children}</>}
        {table && pagination && (
          <tfoot>
            <tr>
              <td
                colSpan={table.getVisibleFlatColumns().length}
                className="h-32"
              >
                <TablePagination table={table} />
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </>
  );
};

/** For use with React Table */
export const TableHead = <T,>({ table }: { table: TableType<T> }) => {
  const keyboardEvent = useKeyPress('Tab');
  const [showFocus, setShowFocus] = useState(true);
  const [activeHeader, setActiveHeader] = useState<string | null>(
    table.getAllColumns()[0]?.id ?? null
  );
  const visibleColumns = table.getVisibleFlatColumns();
  const activeColumn = table.getColumn(activeHeader ?? '');

  useEffect(() => {
    if (keyboardEvent && !showFocus) {
      setShowFocus(true);
    }
  }, [keyboardEvent]);

  return (
    <>
      <colgroup>
        {table.getVisibleFlatColumns().map((column) => (
          <col
            key={column.id}
            className={clsx(column.columnDef.meta?.colWidth)}
          />
        ))}
      </colgroup>

      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr
            key={headerGroup.id}
            tabIndex={0}
            className="group outline-none"
            data-cy={headerGroup.id}
            onMouseDown={() => setShowFocus(false)}
            onKeyDown={(event) => {
              if (!activeColumn) return;
              if (
                (event.key === 'Enter' || event.key === ' ') &&
                activeColumn.getCanSort()
              ) {
                event.preventDefault();
                activeColumn.toggleSorting();
              }

              if (event.key === 'ArrowUp') {
                event.preventDefault();
                activeColumn.getIsSorted() !== 'asc'
                  ? activeColumn.toggleSorting(false)
                  : activeColumn.clearSorting();
              } else if (event.key === 'ArrowDown') {
                event.preventDefault();
                activeColumn.getIsSorted() !== 'desc'
                  ? activeColumn.toggleSorting(true)
                  : activeColumn.clearSorting();
              }

              if (event.key === 'ArrowLeft') {
                event.preventDefault();
                const index = visibleColumns.findIndex(
                  (column) => column.id === activeHeader
                );

                const prevColumn = visibleColumns[index - 1];
                if (prevColumn && prevColumn.getCanSort()) {
                  setActiveHeader(prevColumn.id);
                }
              } else if (event.key === 'ArrowRight') {
                event.preventDefault();
                const index = visibleColumns.findIndex(
                  (column) => column.id === activeHeader
                );
                const nextColumn = visibleColumns[index + 1];
                if (nextColumn && nextColumn.getCanSort()) {
                  setActiveHeader(nextColumn.id);
                }
              }

              setShowFocus(true);
            }}
          >
            {headerGroup.headers.map((header) => (
              <TableHeader
                key={header.id}
                data-cy={header.id}
                onClick={header.column.getToggleSortingHandler()}
                align={header.column.columnDef.meta?.align}
                sorting={
                  header.column.getCanSort()
                    ? header.column.getIsSorted()
                    : undefined
                }
                className={clsx(
                  {
                    'group-focus:bg-pen-blue-light':
                      header.column.id === activeHeader && showFocus,
                  },
                  header.column.columnDef.meta?.colWidth
                )}
                role={header.column.getCanSort() ? 'button' : undefined}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </TableHeader>
            ))}
          </tr>
        ))}
      </thead>
    </>
  );
};

/** For use with React Table */
export const TableBody = <T,>({
  table,
  rowWrapper,
  children,
}: {
  table: TableType<T>;
  rowWrapper?: (props: { children: ReactNode; row: Row<T> }) => ReactNode;
  children?: ReactNode;
}) => {
  const minimumRows = table.getState().pagination.pageSize ?? 0;
  const rowModel = table.getRowModel();
  const remainder = minimumRows - rowModel.rows.length;
  const pages = table.getPageCount();
  const columns = table.getVisibleFlatColumns();
  const { getRef } = useKeyboardNavigation();
  const shouldFillPage = !!table.options.meta?.fillRowsToMatchPageSize;

  const Wrapper = rowWrapper ?? Fragment;

  return (
    <tbody className="animate-fade-in">
      {rowModel.rows.map((row, index) => {
        const wrapperProps = rowWrapper ? { row: row } : {};

        return (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          <Wrapper {...(wrapperProps as any)} key={row.id}>
            <tr
              data-cy={row.id}
              key={row.id}
              className="group table-row"
              ref={(el) => getRef(index, el)}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  data-cy={cell.id}
                  truncate={cell.column.columnDef.meta?.truncate}
                  align={cell.column.columnDef.meta?.align}
                  className={clsx(cell.column.columnDef.meta?.colWidth)}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </tr>
          </Wrapper>
        );
      })}

      {children}

      {pages > 1 &&
        remainder > 0 &&
        shouldFillPage &&
        new Array(remainder).fill(null).map((_, index) => (
          <tr key={index} className="h-56">
            {columns.map((column) => (
              <TableCell key={column.id} noPadding>
                &nbsp;
              </TableCell>
            ))}
          </tr>
        ))}
    </tbody>
  );
};

export const TableHeader = ({
  children,
  className,
  sorting,
  ...props
}: DetailedHTMLProps<
  TableHTMLAttributes<HTMLTableCellElement>,
  HTMLTableCellElement
> & {
  sorting?: SortDirection | false;
}) => {
  return (
    <th
      className={clsx(
        'interface-sm-regular h-32 border-b border-neutral-light-6',
        {
          'cursor-pointer select-none hover:bg-neutral-light-3 hover:text-neutral-dark-7':
            typeof sorting !== 'undefined',
        },
        className
      )}
      {...props}
    >
      <div className="flex w-fit items-center gap-8 px-8">
        {children}
        {sorting === 'asc' && <CaretUp weight="fill" />}
        {sorting === 'desc' && <CaretDown weight="fill" />}
      </div>
    </th>
  );
};

export const TableCell = ({
  children,
  className,
  noPadding = false,
  truncate = true,
  ...props
}: DetailedHTMLProps<
  TableHTMLAttributes<HTMLTableCellElement>,
  HTMLTableCellElement
> & {
  noPadding?: boolean;
  truncate?: boolean;
}) => {
  return (
    <td
      className={clsx('h-56 border-b border-neutral-light-6', className)}
      {...props}
    >
      <div
        className={clsx('body-sm-regular flex w-fit max-w-full items-center', {
          'px-8': !noPadding,
        })}
      >
        <div className={clsx('relative', { truncate: truncate })}>
          {children}
        </div>
      </div>
    </td>
  );
};
