import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  TableHTMLAttributes,
  useMemo,
  useRef,
} from 'react';

// eslint-disable-next-line no-restricted-imports
import { Button } from '@remarkable/ark-web';
import { Table } from '@tanstack/react-table';
import clsx from 'clsx';
import { CaretLeft, CaretRight, DotsThree } from 'phosphor-react';

import { cn } from 'src/utils/classNamesHelper';
import { useWindowSize } from 'src/utils/useWindowSize';

/** For use with React Table */
export const TablePagination = <T,>({
  table,
  className,
  compact = false,
  ...props
}: DetailedHTMLProps<
  TableHTMLAttributes<HTMLTableElement>,
  HTMLTableElement
> & { table: Table<T>; compact?: boolean }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const pageIndex = Math.min(
    table.getState().pagination.pageIndex ?? 0,
    table.getPageCount() - 1
  );
  const pageCount = table.getPageCount();

  const indexButtons = useMemo(() => {
    const isMobile = windowSize.width < 420;
    const isTablet = windowSize.width < 560;

    // Number of pages to show on either side of the current page
    let offsetPages = 0;

    if (isMobile || compact) {
      offsetPages = 0;
    } else if (isTablet) {
      offsetPages = 1;
    } else {
      offsetPages = 2;
    }

    const rangeSize = offsetPages * 2 + 1;

    // The range should not go negative or past the last page
    const initialStartIndex = pageIndex - offsetPages;
    const startIndex = Math.max(
      0, // don't go negative
      Math.min(initialStartIndex, pageCount - rangeSize) // don't go closer to the end than the range size
    );

    const initialEndIndex = pageIndex + offsetPages + 1;
    const endIndex = Math.max(
      Math.min(pageCount, initialEndIndex), // don't go past the last page
      Math.min(rangeSize, pageCount) // don't go closer to the start than the range size, unless there are fewer pages than the range size
    );

    // Generate indexes for the buttons
    const indexButtons = [];
    for (let i = startIndex; i < endIndex; i++) {
      indexButtons.push(i);
    }

    // Render at least one index button
    if (indexButtons.length === 0) return [0];

    return indexButtons;
  }, [pageIndex, pageCount, windowSize, compact]);

  if (!table.getPaginationRowModel()) return null;

  if (!table.getState().pagination.pageSize) return null;

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <nav
      className={cn(
        'group mt-16 flex items-center justify-center gap-8 rounded outline-none',
        className
      )}
      data-cy="table-pagination"
      ref={containerRef}
      onMouseDown={(e) => e.preventDefault()}
      onKeyDown={(e) => {
        if (e.key === 'ArrowLeft' && table.getCanPreviousPage()) {
          e.preventDefault();
          table.previousPage();
          containerRef.current?.focus();
        }
        if (e.key === 'ArrowRight' && table.getCanNextPage()) {
          e.preventDefault();
          table.nextPage();
          containerRef.current?.focus();
        }

        if (e.key === 'Home') {
          e.preventDefault();
          table.setPageIndex(0);
          containerRef.current?.focus();
        }

        if (e.key === 'End') {
          e.preventDefault();
          table.setPageIndex(pageCount - 1);
          containerRef.current?.focus();
        }

        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
          e.preventDefault();
        }
      }}
      // make focusable so we can listen for arrow keys
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      {...props}
    >
      <div className="flex items-center gap-8 rounded px-16 py-12">
        <IndexButton
          disabled={!table.getCanPreviousPage()}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            table.getCanPreviousPage() && table.previousPage();
          }}
          data-cy="table-pagination-previous-button"
          aria-label="Load previous invoices page"
          edge
        >
          <CaretLeft />
        </IndexButton>

        {!compact && !indexButtons.includes(0) && (
          <IndexButton
            onClick={() => table.setPageIndex(0)}
            data-cy="table-pagination-start-button"
            aria-label={`Load first invoices page`}
          >
            1
          </IndexButton>
        )}

        {!compact && !indexButtons.includes(1) && pageIndex > 1 && (
          <DotsThree className="text-gray-800" weight="fill" />
        )}

        {indexButtons.map((i) => (
          <IndexButton
            key={i}
            onClick={(e) => {
              e.currentTarget.focus();
              table.setPageIndex(i);
            }}
            active={i === pageIndex}
            className={clsx({
              'group-focus:bg-pen-blue-light group-focus:text-gray-900':
                i === pageIndex,
            })}
            data-pageindex={i}
            data-cy="table-pagination-index-button"
            aria-label={`Load invoices page ${i + 1}`}
          >
            {i + 1}
          </IndexButton>
        ))}

        {!compact && !indexButtons.includes(pageCount - 2) && pageCount > 1 && (
          <DotsThree className="text-gray-800" weight="fill" />
        )}

        {!compact && !indexButtons.includes(pageCount - 1) && pageCount > 1 && (
          <IndexButton
            onClick={() => table.setPageIndex(pageCount - 1)}
            data-cy="table-pagination-end-button"
          >
            {pageCount}
          </IndexButton>
        )}

        <IndexButton
          disabled={!table.getCanNextPage()}
          data-cy="table-pagination-next-button"
          aria-label="Load next invoices page"
          edge
        >
          <CaretRight />
        </IndexButton>
      </div>
    </nav>
  );
};

const IndexButton = ({
  className,
  active = false,
  disabled = false,
  edge = false,
  ...props
}: { edge?: boolean } & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { active?: boolean }) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Button<any>
      as="button" // Add the 'as' prop with the value 'button'
      className={cn('!rounded transition-none', className)}
      variant={
        active ? 'primary-neutral' : edge ? 'tertiary-neutral' : 'tertiary'
      }
      size="small"
      tabIndex={-1}
      data-active={active}
      disabled={disabled}
      {...props}
    />
  );
};
