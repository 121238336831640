import { useEffect, useState } from 'react';

import { DevTool } from '@hookform/devtools';
import { Check } from '@phosphor-icons/react';
import {
  Checkbox,
  ErrorMessage,
  Field,
  Label,
  Typography,
} from '@remarkable/ark-web';
import * as Sentry from '@sentry/react';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useIsMutating } from '@tanstack/react-query';
import { CaretDown, CaretUp } from 'phosphor-react';
import { Controller, useForm } from 'react-hook-form';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { queryKeys, useCart } from 'src/api/queries';
import {
  Button,
  Divider,
  Form,
  Input,
  NotificationBox,
  SomethingWentWrong,
  Spinner,
  SplitHorizontalLayout,
} from 'src/components';
import { CountryPicker2 } from 'src/components/CountryPicker2';
import { Input2 } from 'src/components/Input2';
import { InputTaxId } from 'src/components/InputTaxId';
import { LinkExternal } from 'src/components/LinkExternal';
import { StatePicker2 } from 'src/components/StatePicker2';
import { IS_DEVELOPMENT } from 'src/config';
import { COUNTRIES } from 'src/utils/countryList';
import { createContentSwitch } from 'src/utils/createContentSwitch';
import {
  CustomStripeError,
  StripeIntentType,
  useStripe,
} from 'src/utils/stripe';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';

import { CartItemConnect } from './CartItemConnect';
import { CartItemConnectWithTrial } from './CartItemConnectWithTrial';
import { CheckoutFormState } from './Checkout.types';
import { Price } from './Price';
import { useCheckout } from './useCheckout';

const TermsAndConditionsLink = () => (
  <LinkExternal to={SUPPORT_URL.LEGAL_OVERVIEW} inline>
    Terms and Conditions
  </LinkExternal>
);

export const CheckoutForm = ({
  cart,
}: {
  cart: ReturnType<typeof useCart>;
}) => {
  const form = useForm<CheckoutFormState>({
      defaultValues: {
        country: cart.country.data?.value,
      },
    }),
    { watch, resetField } = form;

  const hasAcceptedTerms = watch('hasAcceptedTerms');

  const countryFieldValue = watch('country');

  const stripeIntentType =
    cart.checkoutType === 'store'
      ? StripeIntentType.Payment
      : StripeIntentType.Setup;
  const { stripe, paymentElementProps } = useStripe(stripeIntentType);

  const checkout = useCheckout();

  // Country can change outside form
  useEffect(() => {
    if (cart.country.data && cart.country.data.value !== countryFieldValue) {
      // Only reset the field if it has not been touched or changed
      if (
        !form.formState.touchedFields['country'] ||
        !form.formState.dirtyFields['country']
      ) {
        form.resetField('country', { defaultValue: cart.country.data.value });
        form.setValue('country', cart.country.data.value);
        resetField('state');
        resetField('postalCode');
      }
    }
  }, [cart.country.data]);

  // ======================= Form ========================

  const isUnitedStates = countryFieldValue === 'US';
  const isCanada = countryFieldValue === 'CA';

  // ================== Visuals ==================

  const [showBillingInfo, setShowBillingInfo] = useState(false);

  const isMutatingCurrency =
    useIsMutating({
      mutationKey: queryKeys.checkout.currency,
    }) > 0;
  const isMutatingTax =
    useIsMutating({ mutationKey: queryKeys.checkout.tax }) > 0;
  const isUpdatingCurrency = cart.updateCurrency.isPending;

  if (
    cart.setup.isPending ||
    cart.country.isPending ||
    cart.details.isPending
  ) {
    return <Spinner />;
  }

  if (cart.details.isError || cart.setup.isError || cart.country.isError) {
    return <SomethingWentWrong />;
  }

  // We should disable the most important fields in the form while performing these actions.
  const disableCurrencyRelatedFormFields =
    isMutatingCurrency || isMutatingTax || isUpdatingCurrency;

  const showOnCheckoutType = createContentSwitch(cart.checkoutType);

  return (
    <div className="text-black" data-cy="checkout-form">
      <Form
        onSubmit={(data) => {
          if (data.country !== cart.country.data?.value) {
            Sentry?.captureEvent({
              message:
                '[Checkout]: Form submitted, but global country value and form country value are out of sync. Falling back to form value.',
              extra: {
                formCountryValue: data.country,
                globalCountryValue: cart.country.data?.value,
              },
            });
          }
          checkout.mutate(data);
        }}
        className="flex flex-col gap-24"
        {...form}
      >
        <Divider className="my-0" />

        <div>
          {showOnCheckoutType({
            ['connectOffer']: <CartItemConnectWithTrial />,
            ['winbackOffer']: <CartItemConnectWithTrial />,
            ['store']: <CartItemConnect />,
          })}
        </div>

        <Divider className="my-0" />

        <SplitHorizontalLayout>
          <Input
            name="firstName"
            label="First name"
            autoComplete="given-name"
            required
          />
          <Input
            name="lastName"
            label="Last name"
            autoComplete="family-name"
            required
          />
        </SplitHorizontalLayout>

        <SplitHorizontalLayout>
          <Controller
            control={form.control}
            name="country"
            rules={{
              required: true,
              onChange(event: React.ChangeEvent<HTMLSelectElement>) {
                const newCountry = COUNTRIES.find(
                  (c) => c.value === event.target.value
                );

                if (!newCountry) {
                  throw new Error('Country does not exist!');
                }

                resetField('state');
                resetField('postalCode');
                cart.setCountry(newCountry);
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <Field className="w-full">
                  <Label htmlFor={field.name}>Country</Label>

                  <CountryPicker2
                    id={field.name}
                    hasError={!!fieldState.error}
                    disabled={disableCurrencyRelatedFormFields}
                    aria-invalid={!!fieldState.error}
                    aria-errormessage={fieldState.error?.message}
                    aria-busy={disableCurrencyRelatedFormFields}
                    {...field}
                  />

                  {fieldState.error?.message && (
                    <ErrorMessage data-cy="country-error-message">
                      {fieldState.error.message}
                    </ErrorMessage>
                  )}
                </Field>
              );
            }}
          />

          {isUnitedStates && (
            <Controller
              control={form.control}
              name="postalCode"
              rules={{
                required: 'Zip / postal code is required',
                deps: ['country', 'state'],
                validate: {
                  length: (value: string | undefined) => {
                    if (!value) return 'ZIP code must be provided';
                    const zipPattern = /^\d{5}([-]\d{4})?$/i;
                    return (
                      zipPattern.test(value) ||
                      'Please enter a valid zip code for the United States, for example 90210 or 90210-1234.'
                    );
                  },
                  tax: async (value, form) => {
                    if (!value || !form.state || !form.country) return false;

                    const result = await cart.updateTax
                      .mutateAsync({
                        country: form.country,
                        county: form.state,
                        zip: value,
                        city: form.city,
                      })
                      .then(() => true)
                      .catch(() => 'Invalid address');

                    return result;
                  },
                },
              }}
              shouldUnregister
              render={({ field, fieldState }) => (
                <Field className="w-full">
                  <Label htmlFor={field.name}>Zip / Postal code</Label>

                  <Input2
                    data-cy="postal-code-input"
                    id={field.name}
                    inputMode="numeric"
                    {...field}
                    disabled={disableCurrencyRelatedFormFields}
                    hasError={!!fieldState.error}
                    aria-invalid={!!fieldState.error}
                    aria-errormessage={fieldState.error?.message}
                  />

                  {fieldState.error?.message && (
                    <ErrorMessage data-cy="postal-code-error-message">
                      {fieldState.error.message}
                    </ErrorMessage>
                  )}
                </Field>
              )}
            />
          )}
        </SplitHorizontalLayout>

        {(isUnitedStates || isCanada) && (
          <Controller
            control={form.control}
            name="state"
            rules={{
              deps: ['country', 'postalCode'], // Will trigger validation on postalCode that in turn updates the tax
              required: isCanada ? 'Province is required' : 'State is required',
            }}
            render={({ field, fieldState }) => {
              const country = form.watch('country');
              const isCanada = country === 'CA';
              return (
                <Field className="w-full">
                  <Label htmlFor={field.name}>
                    {isCanada ? 'Province' : 'State'}
                  </Label>

                  <StatePicker2
                    country={country}
                    id={field.name}
                    disabled={disableCurrencyRelatedFormFields}
                    hasError={!!fieldState.error}
                    aria-invalid={!!fieldState.error}
                    aria-errormessage={fieldState.error?.message}
                    {...field}
                  />

                  {fieldState.error?.message && (
                    <ErrorMessage data-cy="state-error-message">
                      {fieldState.error.message}
                    </ErrorMessage>
                  )}
                </Field>
              );
            }}
          />
        )}

        <PaymentElement {...paymentElementProps} />

        <Divider className="mb-0" />

        <button
          data-cy="billing-info-optional"
          aria-expanded={showBillingInfo}
          className="body-lg-regular relative h-32 w-full cursor-pointer border-none bg-none text-left"
          onClick={(e) => {
            tracker.trackEvent(
              new ButtonClicked({
                component_location:
                  ComponentLocations.CHECKOUT.CONNECT_CHECKOUT,
                text: e.currentTarget.innerText,
                action: 'toggle billing info in checkout',
              })
            );
            setShowBillingInfo(!showBillingInfo);
          }}
          type="button"
        >
          <Typography variant="interface-lg-semibold">
            Billing information <span className="font-regular">(optional)</span>
          </Typography>
          {!showBillingInfo ? (
            <CaretDown size={32} className="absolute right-12 top-0" />
          ) : (
            <CaretUp size={32} className="absolute right-12 top-0" />
          )}
        </button>

        {showBillingInfo && (
          <>
            <Input
              data-cy="billing-address1"
              label="Billing address 1"
              autoComplete="street-address"
              name="billingAddress1"
            />
            <Input
              data-cy="billing-address2"
              name="billingAddress2"
              label="Billing address 2"
            />

            <SplitHorizontalLayout>
              {!isUnitedStates && (
                <Input
                  data-cy="billing-post-code"
                  name="postalCode"
                  label="Postal code"
                  inputMode="numeric"
                  autoComplete="postal-code"
                />
              )}
              <Input
                data-cy="billing-city"
                name="city"
                label="City"
                autoComplete="address-level2"
              />
            </SplitHorizontalLayout>

            <Input
              data-cy="billing-company-name"
              name="companyName"
              label="Company name"
              autoComplete="organization"
            />
            <InputTaxId className="my-0" country={countryFieldValue} />
          </>
        )}

        <Divider className="my-0" />

        <div className="flex items-start gap-8">
          <Checkbox
            data-cy="accept-terms-and-conditions"
            id="terms"
            name="hasAcceptedTerms"
            required
            aria-label="Accept terms and conditions checkbox"
            onClick={() => {
              tracker.trackEvent(
                new ButtonClicked({
                  component_location:
                    ComponentLocations.CHECKOUT.CONNECT_CHECKOUT,
                  text: `Accept terms and conditions checkbox`,
                  action: `toggle accept TOC checkbox ${
                    hasAcceptedTerms ? 'off' : 'on'
                  }`,
                })
              );
            }}
          ></Checkbox>
          <Typography variant="body-sm-regular" className="my-0">
            {showOnCheckoutType({
              ['store']: (
                <>
                  By subscribing you accept the <TermsAndConditionsLink /> for
                  Connect and agree to a payment of <Price cart={cart} />
                  {'. '}
                  Cancel anytime.
                </>
              ),
              ['connectOffer']: (
                <>
                  I accept the <TermsAndConditionsLink /> for Connect and agree
                  to a payment of <Price cart={cart} /> once the free
                  subscription period ends. Cancel anytime.
                </>
              ),
              ['winbackOffer']: (
                <>
                  I accept the <TermsAndConditionsLink /> for Connect and agree
                  to a payment of <Price cart={cart} /> once the free
                  subscription period ends. Cancel anytime.
                </>
              ),
            })}
          </Typography>
        </div>

        {checkout.isError &&
          (checkout.error instanceof CustomStripeError ? (
            <NotificationBox
              data-cy="checkout-error-box"
              variant="error"
              title={
                checkout.error.data.type === 'validation_error'
                  ? 'Please check your information'
                  : 'Something went wrong during checkout'
              }
              className="my-16"
            >
              {checkout.error.data.type === 'validation_error' ||
              checkout.error.data.type === 'card_error'
                ? checkout.error.data.message
                : 'Please check your information and try again. If the problem persists, please contact support.'}
            </NotificationBox>
          ) : (
            <NotificationBox
              data-cy="checkout-error-box"
              variant="error"
              title="Something went wrong during checkout"
              className="my-16"
            >
              Please check your information and try again. If the problem
              persists, please contact support.
            </NotificationBox>
          ))}

        {stripe && (
          <div className="mt-24 flex justify-center">
            <Button
              data-cy="activate-connect-button"
              type="submit"
              variant="primary"
              className="ml-auto w-full lm:w-fit"
              disabled={disableCurrencyRelatedFormFields}
              loading={checkout.isPending}
              onClick={(e) => {
                tracker.trackEvent(
                  new ButtonClicked({
                    component_location:
                      ComponentLocations.CHECKOUT.CONNECT_CHECKOUT,
                    text: e.currentTarget.innerText,
                    action: 'submit payment information',
                  })
                );
              }}
              aria-busy={checkout.isPending || disableCurrencyRelatedFormFields}
            >
              <span>
                {showOnCheckoutType({
                  ['connectOffer']: 'Activate free trial',
                  ['winbackOffer']: 'Activate free trial',
                  ['store']: 'Subscribe',
                })}{' '}
              </span>
              <Check />
            </Button>
          </div>
        )}
      </Form>

      {IS_DEVELOPMENT && <DevTool control={form.control} />}
    </div>
  );
};
